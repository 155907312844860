(function () {

    document.addEventListener("DOMContentLoaded", () => {

        $('#awards-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 7000,
            arrows: false,
            variableWidth: true,
            cssEase: 'linear',
            dots: false,
            pauseOnHover: true
        });

    });

}());